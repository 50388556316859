.form_group {
    display: block;
    margin-bottom: 15px;
    position: relative;
}

.form_group input {
    position: absolute;
    z-index: 10;
    opacity: 0;
    width: 27px;
    height: 27px;
    left: 0;
    cursor: pointer;
    overflow: hidden;
}

.form_group label {
    position: relative;
    cursor: pointer;
}

.form_group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #666;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    /*padding: 10px;*/
    border-radius: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    width: 24px;
    height: 24px;
}

.form_group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #666;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
