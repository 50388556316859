.container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.circle3 {
    display: block;
    position: absolute;
    /*top: 50%;*/
    /*left: 50%;*/
    height: 66px;
    width: 66px;
    border: 8px rgba(0, 0, 0, 0) solid;
    border-top: 8px #666 solid;
    border-right: 8px #666 solid;
    border-bottom: 8px #666 solid;
    border-radius: 50%;
    -webkit-animation: spin3 1s infinite linear;
    animation: spin3 1s infinite linear;
}



@-webkit-keyframes spin3 {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spin3 {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
