.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 794px;
    width: 100%;
    padding: 20px 0 0 0;

}

.border_box{
    height: 100%;
    border-bottom: 1px solid #666666;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*padding: 20px 0;*/
    margin-left: 20px;
    padding-bottom: 20px;
}

.name {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
}

.id {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #666666;
    margin-top: 10px;
}

.left_side {
    display: flex;
    align-items: flex-start;
    width: 100%;

}

.info_box {
    cursor: pointer;
}

.edit {
    cursor: pointer;
}