.container {
    margin-top: 40px;
    display: flex;
}

.left{
    border: 1px solid #666666;
    border-radius: 6px;
    max-width: 480px;
    width: 100%;
    padding: 20px;
    height: fit-content;
}

.right{
    margin-top: -40px;
    width: 100%;
    margin-left: 80px;
}

.item_box{
    margin-top: 20px;
}

.item p {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    margin-top: 10px;
    color: #FFFFFF;
}

.about {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
}

.item{
    margin-top: 20px;
}

.item span {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #666666;
}
