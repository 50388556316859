.item {
    width: 100%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: #2F2F2F;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 10px;
}

.item svg path {
    stroke: #666;

}

.item svg {
    position: absolute;
    right: 5px;
    height: 30px;
    width: 30px;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}

.title2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: right;
    color: #FFFFFF;
    margin-right: 40px;
}


