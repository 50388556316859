.item {
    border: 1px solid #666666;
    border-radius: 6px;
    padding: 20px;
    max-width: 480px;
    width: 100%;
    margin-top: 40px;
}

.about {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #fff;
}

.id, .name {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #666666;
    margin-top: 20px;
}

.id_title, .name_title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 10px;
}