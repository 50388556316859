.edit_box{
    max-width: 400px;
    width: 100%;
    margin-top: 40px;
}

.id_box span {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
}

.id_box_main {
    background: #666666;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #666666;
    border-radius: 6px;
    width: 100%;
    padding: 15px 10px;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}

.btn_box{
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gray {
    background: none !important;
    color: #fff !important;
    border:1px solid #FFFFFF !important;
}