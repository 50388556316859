
.sex p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.gray {
    max-width: 100%;
    margin-top: 20px;
    width: 100%;
}

.text_area {
    background: none;
    max-width: 400px;
    width: 100%;
    height: 96px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    background: #2F2F2F;
    border: 1px solid #666666;
    border-radius: 6px;
    color: #FFFFFF;
    padding: 5px 10px;
}


.text_area_box p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    margin: 20px 0 10px 0;
}

.btn {
    background: #B66959;
    border-radius: 6px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 40px;
}

.number_box p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 0;
}

.number_box input {
    width: 100%;
    background: #2F2F2F;
    border: 1px solid #666666;
    border-radius: 5px;
    height: 52px;
    outline: none;
    margin-top: 15px;
    color: #fff;
    padding-left: 10px;
}

.right {
    display: none;
}

.id_box {
    display: none;
}

@media screen and (min-width: 1024px) {
    .right {
        display: block;
        width: 100%;
        margin-left: 80px;
    }

    .mobile_description {
        display: none;
    }

    .content_box {
        margin-top: 40px;
        display: flex;
    }

    .left {
        max-width: 400px;
        width: 100%;
    }

    .text_area_box p {
        margin-top: 0;
    }

    .text_area textarea, .text_area {
        height: 140px;
    }

    .btn_box {
        max-width: 400px;
        width: 100%;
        display: flex;
        margin-top: 60px;
    }

    .gray, .btn {
        margin-bottom: 0;
        margin-top: 0;
    }

    .btn {
        margin-right: 10px;
    }

    .gray {
        margin-left: 10px;
    }

    .id_box {
        display: block;
    }

    .id_box p {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        color: #FFFFFF;
        margin-bottom: 10px;
    }

    .id_box div {
        background: #666666;
        border: 1px solid #666666;
        border-radius: 6px;
        padding: 15px 10px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: #FFFFFF;
    }
}
