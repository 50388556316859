.container{
    margin-top: 40px;
}

.container input {
    max-width: 794px;
    width: 100%;
    height: 48px;
    background: #666666;
    border: 1px solid #666666;
    border-radius: 6px;
    outline: none;
    color: #fff;
    padding-left: 20px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
}

.container input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #fff;
}

.btn_box {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.add {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    max-width: 190px;
    width: 100%;
    height: 48px;
    background: #B66959;
    border-radius: 6px;
    cursor: pointer;
    transition: .3s all;
}

.add:hover {
    background: rgba(182, 105, 89, 0.57);
}

.remove{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 190px;
    width: 100%;
    height: 48px;
    border: 1px solid #666666;
    border-radius: 6px;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #666666;
    cursor: pointer;
    margin-left: 20px;
    background: none !important;
}
