.header {
    height: 79px;
    width: 100%;
    background: #0D0D0D;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
}

@media screen and (min-width: 1024px) {
    .header{
        display: none;
    }
}
