.pacient {
    min-height: 100%;
    padding: 100px 0;
    overflow-x: hidden;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    height: 100%;
    color: #FFFFFF;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 20px;
}

.arrow {

}

.all_pacient {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    position: relative;
}

.plus{
    position: absolute;
    left: 20px;
    bottom: 20px;
}

.arrow {
    position: absolute;
    left: 20px;
    bottom: 20px;
    transform: rotate(180deg);
}

.edit{
    position: absolute;
    right: 20px ;
    bottom: 20px;
}

@media screen and (min-width: 1024px) {
    .pacient {
        padding: 0px 0;
    }
}