.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    max-width: 190px;
    width: 100%;
    height: 48px;
    background: #B66959;
    border-radius: 6px;
    cursor: pointer;
    transition: .3s all;
}