.header {
    display: flex;
    align-items: center;
}

.header p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    margin-left: 7px;
}
