.img_box {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 180px;
    width: 100%;
    height: 180px;
    position: relative;
    border-radius: 6px 6px 6px 6px;
    /*overflow: hidden;*/
}

.edit_box {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.close_box {
    position: absolute;
    top: 0px;
    right: -1px;
    height: 48px;
    width: 48px;
    background: #2F2F2F;
    border-radius: 0 0 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.input_box {
    max-width: 400px;
    width: 100%;
    margin-top: 40px;
}

.text_area textarea {
    background: none;
    max-width: 400px;
    width: 100%;
    height: 140px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    background: #2F2F2F;
    border: 1px solid #666666;
    border-radius: 6px;
    color: #FFFFFF;
    padding: 5px 10px;
}


.text_area p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    margin: 20px 0 10px 0;
}

.btn_box {
    max-width: 400px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.added_img_box > input {
    display: none;
}

.added_box > input {
    display: none;
}

.added_img_box {
    border: 1px solid #666666;
    border-radius: 6px;
    max-width: 400px;
    height: 180px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.added_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #666666;
    height: 50%;
}

.camera_box svg path {
    stroke: #666666;
}

.camera_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
}

.added_box p, .camera_box p {
    margin-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #666666;
}

.modal_title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #EE6B4E;
    margin-top: 40px;
}

.modal_box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    position: relative;
    z-index: 9999999999999;
}

.modal_sub_title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.btn {
    display: none;
}

@media screen and (max-width: 1024px) {
    .btn_box {
        max-width: 100%;
        flex-direction: column;
        width: 100%;
    }

    .btn1, .btn2 {
        max-width: 100%;
    }

    .btn2 {
        margin-top: 20px;
    }

    .img_box {
        max-width: 100%;
        height: 335px;
    }

    .edit_box {
        margin-top: 0px;
    }

    .added_img_box {
        display: none;
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 10px;
        width: 100%;
        height: 48px;
        background: #B66959;
        border-radius: 6px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #FFFFFF;
    }

    .btn > input {
        display: none;
    }
}

