.btn {
    width: 100%;
    height: 48px;
    padding: 15px 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    background: #B66959;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    margin-top: 20px;
}

.empty_images{
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    margin-top: 40px;
}

.gray {
    max-width: 100%;
    margin-top: 20px;
}

.about {
    display: none;
}

.desk_box {
    display: none;
}

.right {
    display: none;
}

@media screen and (min-width: 1024px) {
    .about {
        display: block;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: #FFFFFF;
        margin-bottom: 20px;
    }

    .right {
        display: block;
        max-width: 794px;
        width: 100%;
        margin-left: 80px;
        margin-top: -40px;
    }

    .mobile_box {
        display: none;
    }

    .content_box {
        display: flex;
        margin-top: 40px;
    }

    .desk_box {
        display: block;
    }

    .left {
        border: 1px solid #666666;
        border-radius: 6px;
        max-width: 480px;
        width: 100%;
        padding: 20px;
        height: fit-content;
    }

    .added_image_btn {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #FFFFFF;
        padding: 15px 22px;
        background: #B66959;
        border-radius: 6px;
        max-width: 190px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        cursor: pointer;
    }

    .items_box {
        margin-top: 20px;
    }

    .btn {
        background: rgb(102, 102, 102);
    }
}
