.added_box{
    max-width: 400px;
    width: 100%;
    margin-top: 40px;
}

.btn_box{
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: space-between;
}

.gray {
    background: none !important;
    color: #fff !important;
    border:1px solid #FFFFFF !important;
}