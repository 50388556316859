.pacient{
    margin-top: 30px;
}

.gray{
    background: none !important;
    color: #fff !important;
    border:1px solid #FFFFFF !important;
    margin-top: 20px;
}
