.setting {
    min-height: 100%;
    padding: 70px 0 100px 0;
    overflow-x: hidden;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    height: 100%;
    color: #FFFFFF;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 20px;
}

.item_title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
}

.box_item {
    margin-top: 20px;
}

.logout {
    background: #B66959;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    border-radius: 6px;
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    cursor: pointer;
}
