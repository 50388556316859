.container {
    display: flex;
    padding: 20px 0;
    max-width: 800px;
    width: 100%;
}

.content {
    width: 100%;
    border-bottom: 1px solid #666666;
    padding-bottom: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fullName {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
}

.specialization {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #B66959;
    margin-top: 10px;
}

.age {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 10px;
}

.id {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #666666;
    margin-top: 5px;
}

.click_box {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: right;
    color: #FFFFFF;
    cursor: pointer;
}
