.title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
}

.title_box {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.title_box svg {
    transform: rotate(180deg);
    margin-right: 10px;
}
