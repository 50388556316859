.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 10px;
    width: 100%;
    height: 48px;
    background: #B66959;
    border-radius: 6px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}

.btn > input {
    display: none;
}

.modal_title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #EE6B4E;
}

.modal_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 9999999999999;
}

.modal_sub_title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
}

.text_area {
    background: none;
    max-width: 400px;
    width: 100%;
    height: 96px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    background: #2F2F2F;
    border: 1px solid #666666;
    border-radius: 6px;
    color: #FFFFFF;
    padding: 5px 10px;
}


.text_area_box p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    margin: 20px 0 10px 0;
}

.btns {
    max-width: 100% !important;
    width: 100% !important;
}

.cam {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999999999;
}

.img_box {
    position: relative;
}

.img {
    /*border: .1px solid;*/
    height: 335px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.close {
    position: absolute;
    right: 0;
    top: 0;
    background: #2F2F2F;
    border-radius: 0px 6px 0 6px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.added_img_box {
    display: none;
}

@media screen and (min-width: 1024px) {
    .added_box > input {
        display: none;
    }

    .added_img_box {
        border: 1px solid #666666;
        border-radius: 6px;
        margin-top: 40px;
        max-width: 400px;
        height: 180px;
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .added_box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
        border-bottom: 1px solid #666666;
        width: 100%;
    }

    .camera_box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 100%;
    }

    .camera_box svg path{
        stroke: #666666;
    }

    .added_box p, .camera_box p {
        margin-left: 10px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        color: #666666;
    }

    .btn {
        display: none;
    }

    .input_box {
        max-width: 400px;
        width: 100%;
    }

    .btn_box {
        display: flex;
        margin-top: 60px;
    }

    .btn_box div:first-child {
        margin-right: 10px;
    }

    .btn_box div:last-child {
        margin-left: 10px;
    }

    .img, .img_box {
        max-width: 180px;
        width: 100%;
        height: 180px;
    }

    .close {
        right: -2px;
    }

    .img_box {
        margin-top: 40px;
    }
}
