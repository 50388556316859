.container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2F2F2F;
    padding: 0 20px;
}

.select{
    width: 100%;
    margin-top: 20px;
}

.role_title {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 485px;
    width: 100%;
}

.input_box {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-top: 40px;
}

.input_box input {
    width: 100%;
    background: #2F2F2F;
    /* gray */

    border: 1px solid #666666;
    border-radius: 5px;
    height: 52px;
    outline: none;
    margin-top: 15px;
    color: #fff;
    padding-left: 10px;
}

.btn {
    background: #B66959;
    border-radius: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    margin-top: 40px;
    cursor: pointer;
}

.role_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.role_box div {
    width: 50%;
    border: .1px solid #cecece;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    color: #fff;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 6px;
}

.selected {
    background: #B66959;
}

.disable {
    cursor: not-allowed;
    background: rgba(182, 105, 89, 0.12);
}
