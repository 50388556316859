.item p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
}

.item div {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    background: #2F2F2F;
    border-radius: 6px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    margin-top: 10px;
}

@media screen and (min-width: 1024px) {
    .item p {
        color: #666;
    }

    .item div {
        padding: 0;
        height: fit-content;
    }
}
