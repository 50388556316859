.p{
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
}

.item {
    background: #2F2F2F;
    border-radius: 6px;
    width: 100%;
    min-height: 48px;
    padding: 10px;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

@media screen and (min-width: 1024px) {
    .p{
        font-family: 'Poppins',sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        color: #666666;
    }

    .item {
        background: none;
        border-radius: 6px;
        width: 100%;
        min-height: fit-content;
        padding: 0;
        font-family: 'Poppins',sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        margin-top: 10px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
    }
}