.input_box {
    width: 100%;
}

.input_box input {
    width: inherit;
    background: #2F2F2F;
    border: 1px solid #666666;
    border-radius: 5px;
    height: 52px;
    outline: none;
    margin-top: 15px;
    color: #fff;
    padding-left: 10px;
}

.input_box p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
}


