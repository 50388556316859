.App {
    min-height: 100vh;
    background: #2F2F2F;
    display: flex;
    position: relative;
}

.side_bar {
    min-width: 230px;
    max-width: 230px;
    width: 230px;
    height: 100vh;
    position: sticky;
    top: 0;

    background: #0D0D0D;
    box-shadow: 0px 0px 20px rgba(59, 59, 59, 0.25);
}

.side_bar_doctor {
    position: fixed;
    z-index: 12;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #323232;
    height: 85px;
    overflow: hidden;
    border-top: 1px solid #696969;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.ReactModal__Overlay {
    z-index: 9999999999999999;
}

.content {
    width: 100%;
    background: #2F2F2F;
    padding: 20px 80px;
    overflow: auto;
}

.content_doctor {
    padding: 0px 20px;
    background: #202020;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: #fff;
    opacity: 1;
    display: block;
    background: url('./assets/calendar.svg') no-repeat;
    width: 35px;
    margin-top: 3px;
    height: 30px;
    border-width: thin;
    fill: #fff;
    cursor: pointer;
    user-select: none;
}

@media screen and (min-width: 1024px) {
    .side_bar_doctor {
        min-width: 230px;
        max-width: 230px;
        width: 230px;
        height: 100vh;
        position: sticky;
        top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background: #0D0D0D;
        border-top: 0;
        box-shadow: 0px 0px 20px rgba(59, 59, 59, 0.25);
    }

    .content_doctor{
        padding: 20px 80px;
        background: #2F2F2F;
    }
}
