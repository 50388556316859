.item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 0 0;

}

.box_checkbox_info{
    border-bottom: 1px solid #666666;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.full_name {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
}

.age {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 10px;
}

.id {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #666666;
    margin-top: 5px;
}

.info {
    margin-right: 10px;
}

.checkbox{
    display: none;
}

@media screen and (min-width: 1024px) {

    .item{
        align-items: flex-start;
    }


    .checkbox {
        margin-right: 20px;
        display: block;
    }
}