.inputs_box {
    max-width: 335px;
    width: 100%;
    margin-top: 40px;
}

.right {
    margin-top: 40px;
    margin-left: 80px;
}

.select {
    background-color: red !important;
    color: #fff !important;
}

.sex p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.btn_box {
    display: flex;
    align-items: center;
    margin-top: 50px;
}

.remove {
    margin-left: 20px;
}

.number_box p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 0;
}

.number_box input {
    width: 100%;
    background: #2F2F2F;
    border: 1px solid #666666;
    border-radius: 5px;
    height: 52px;
    outline: none;
    margin-top: 15px;
    color: #fff;
    padding-left: 10px;
}

.action_box {
    display: flex;
    width: 100%;
}

.description_box p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    margin-bottom: 10px;
    color: #FFFFFF;
}

.description_box textarea {
    background: #2F2F2F;
    border: 1px solid #666666;
    border-radius: 6px;
    height: 140px;
    width: 400px;
    outline: none;
    padding: 15px;
    color: #fff;
}

.id_pac {
    height: fit-content;
    width: 100%;
    /*margin-top: 43px;*/
    /*margin-left: 80px;*/
}

.id_pac_content {
    max-width: 335px;
    width: 100%;
    height: 52px;
    padding: 15px 10px;
    background: #666666;
    border: 1px solid #666666;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 10px;
}

.id_pac_box p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
}
