
.modal_box {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    max-width: 438px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-evenly;
}

.btn_confirm {
    justify-content: center;
    margin-top: 20px;
}

.h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    text-align: center;

    /* white */

    color: #FFFFFF;
}

.index {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 40px;
    width: 100%;
}

.indicator {
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    border-radius: 6px;
    margin-top: 15px;
}

.indicator div {
    width: 100%;
    height: 100%;

}

.indicator1 {
    background: #066163;
    position: relative;
}

.indicator1::before {
    content: '0.0 - 0.2';
    top: 60px;
    left: 0;
    transform: translate(50%, -50%);
    position: absolute;
    z-index: 10;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #666666;
}

.indicator2 {
    background: #666666;
    position: relative;
}

.indicator2::before {
    content: '0.21 - 0.49';
    top: 60px;
    left: -10px;
    transform: translate(50%, -50%);
    position: absolute;
    z-index: 10;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #666666;
}

.indicator3 {
    background: #EE6B4E;
    position: relative;
}

.indicator3::before {
    content: '0.5 - 1.0';
    top: 60px;
    left: 0px;
    transform: translate(50%, -50%);
    position: absolute;
    z-index: 10;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #666666;
}

.sub_title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    margin-top: 40px;
    text-align: center;
    color: #FFFFFF;
}

.btn {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 15px 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    background: #B66959;
    border-radius: 6px;
    height: 48px;
    margin-top: 20px;
    max-width: 295px;
    justify-content: center;
}

.action_box {
    display: none;
}

.input_box {
    display: flex;
    width: 100%;
}

.input_box input {
    width: 100%;
    background: #2F2F2F;
    border: 1px solid #666666;
    border-radius: 6px;
    outline: none;
    padding: 15px 10px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}

.input_box input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}

.noPhoto {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #666666;
}

.empty_images {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    margin-top: 50%;
    text-align: center;
    color: #FFFFFF;
}

@media screen and (min-width: 1024px) {
    .gary {
        background: none !important;
        border: 1px solid #666666;
        border-radius: 6px;
        margin-top: 20px;
        color: #666666 !important;
    }

    .input_box {
        display: none;
    }


    .action_box {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
}