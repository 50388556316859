.item {
    width: 100%;
    display: flex;
    margin-top: 20px;
}

.img {
    height: 86px;
    width: 86px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 6px;
}

.date{
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
}

.local {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #FFFFFF;
}

.id {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #666666;
}

.info_box{
    margin-left: 20px;
}