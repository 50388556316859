.show_all_pacient {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input_box {
    display: flex;
    width: 100%;
}

.input_box input {
    width: 100%;
    background: #2F2F2F;
    border: 1px solid #666666;
    border-radius: 6px;
    outline: none;
    padding: 15px 10px;

    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}

.input_box input::placeholder{
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}

.input_box_desc{
    display: none;
}

@media screen and (min-width: 1024px) {
    .input_box{
        display: none;
    }

    .show_all_pacient{
        max-width: 794px;
        width: 100%;
    }

    .input_box_desc{
        display: block;
        margin-bottom: 20px;
    }
}