.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #666666;
}

.item_desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 20px;
}

.date {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
}

.name {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    margin-top: 5px;
    color: #FFFFFF;
    max-width: 200px;
    width: 100%;
}

.local {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #666666;
    margin-top: 10px;
}

.img {
    border-radius: 6px;
    height: 86px;
    min-width: 86px;
    width: 86px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.checkbox {
    display: none;
}

.edit_box {
    cursor: pointer;
}

@media screen and (min-width: 1024px) {
    .item {
        width: 100%;
        padding-top: 30px;
    }

    .main_item_box {
        display: flex;
        align-items: flex-start;
        max-width: 794px;
        width: 100%;
    }

    .checkbox {
        display: block;
        margin-top: 30px;
        margin-right: 20px;
    }

    .name {
        max-width: 100%;
        width: 100%;
    }
}