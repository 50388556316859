.camera {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    /*background: #cecece;*/
    backdrop-filter: blur(5px);
}
.camera1,
.camera2 {
    max-width: 45%;
    position: relative;
}

.canvas {
    display: none;
    position: absolute;
    z-index: -10000;
    left: 5vw;
    top: 5vh;
}

.video{
    height: 90vh;
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
    top: 5vh;
}

.takePhoto {
    position: absolute;
    bottom: 100px;
    left: calc(50% - 25px);
    background-color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    outline: 10px solid rgba(255, 255, 255, .3);
    border: none;
    cursor: pointer;
}

.select {
    position: absolute !important;
    top: 5vh;
    right: 20px;
    width: 200px;
}

.tools {
    display: flex;
    flex-direction: column;
    gap: 8px;

    padding: 8px;

    position: absolute;
    bottom: 20px;
    right: 20px;

    background-color: rgba(255, 255, 255, .5);
    border-radius: 8px;
}
.tools button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 50px;
    width: 50px;

    border-radius: 8px;
    background-color: #E0E0E0;
    border: none !important;
    cursor: pointer;

    transition: .15s ease-in-out;
}
.tools button:hover {
    opacity: .8;
}
.tools button svg {
    width: 70%;
    height: 70%;
}

.tools hr {
    height: 2px;
    color: #828282;
    border: none;
    background-color: #828282;
}
