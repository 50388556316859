.main {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.main img {
    height: 466px;
    width: 466px;
}

@media screen and (max-width: 1024px) {
    .main img{
        height: 147px;
        width: 147px;
    }
}
