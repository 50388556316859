.side_bar {
    width: 100%;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.link_box_doctor {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-around;
}

.items_box {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-around;
}

.logo_box_doctor {
    display: none;
}

.link_box_doctor a {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 130%;
    color: #FFFFFF !important;
}

.item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item svg path {
    stroke: #fff
}


.active_link svg path {
    stroke: #B66959;
}

.link_box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 80px;
}

.link_box a {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.51);
    margin-bottom: 20px;
    transition: .3s all;
}

.active {
    color: #fff !important;
}

.link_box a:hover {
    color: rgba(255, 255, 255, 1);
}

.active_link {
    color: #B66959;
}


.user_box {
    height: 100%;
    display: flex;
    align-items: flex-end;
}

.user_content {
    height: fit-content;
    width: 100%;
}

.user_content p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.59);
}

.user_content h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 2px;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    background: #2F2F2F;
    margin-top: 10px;
    border-radius: 6px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    cursor: pointer;
    color: #FFFFFF;
}

.btn_doctor {
    display: none;
}

@media screen and (min-width: 1024px) {
    .link_box_doctor {
        flex-direction: column;
        height: 100%;
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
        justify-content: flex-start;
    }

    .link_box_doctor a {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        color: rgba(255, 255, 255, 0.51);
        margin-bottom: 20px;
        transition: .3s all;
        text-align: left;
        width: 100%;
    }

    .icon {
        display: none;
    }

    .item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .logo_box_doctor {
        display: flex;
        margin-left: -20px;
    }

    .items_box {
        margin-top: 80px;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        justify-content: flex-start;
    }

    .btn_doctor {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 10px;
        background: #2F2F2F;
        margin-top: 10px;
        border-radius: 6px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        cursor: pointer;
        color: #FFFFFF;
        width: calc(100%);
        margin-bottom: 80px;
    }

    .link_doctor:last-child {
        display: none;
    }
}
