.btn_bot {
    background: #B66959;
    border-radius: 6px;
    width: 100%;
    height: 48px;
    padding: 12px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}

.btn_doctor {
    background: #666666;
}

.remove {
    justify-content: center;
}

.img {
    border-radius: 6px;
    width: 100%;
    height: 335px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.about {
    display: none;
}

.title_ii {
    position: relative;
    bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #666666;
}

@media screen and (min-width: 1024px) {
    .current_item_box {
        margin-top: 40px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    .box_img img, .box_img {
        max-width: 570px;
        /*min-width: 570px;*/
        width: 100%;
        height: 570px;
    }

    .img {
        max-width: 570px;
        /*min-width: 570px;*/
        width: 100%;
        height: 570px;
    }

    .box_info {
        margin-right: 80px;
        max-width: 480px;
        width: 100%;
        padding: 0 20px 20px 20px;
        border: 1px solid #666666;
        border-radius: 6px;
    }

    .about {
        display: block;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: #FFFFFF;
        margin-top: 20px;
    }
}