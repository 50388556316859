.container {
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.left {
    max-width: 480px;
    width: 100%;
}

.images_box {
    margin-top: 40px;
}

.left_top {
    padding: 20px;
    border: 1px solid #666666;
    border-radius: 6px;
    max-width: 480px;
    width: 100%;
}

.left_bottom {
    padding: 20px;
    border: 1px solid #666666;
    border-radius: 6px;
    max-width: 480px;
    width: 100%;
    margin-top: 20px;
}

.about {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
}

.item {
    margin-top: 20px;
}

.item span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #666666;

}

.item p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 10px;
}

.doctor_name {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 20px;
}

.doctor_pac {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #B66959;
    margin-top: 10px;
}

.doctor_years {
    margin-top: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}

.doctor_id {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #666666;
    margin-top: 5px;
}

.right {
    margin-left: 80px;
    width: 100%;
    margin-top: -40px;
}